import * as React from 'react';
import transformImage from '@adecco/base-app/src/cloudinary/transformImage';
// @ts-ignore
import { AdvancedImage } from '@cloudinary/react';

type CloudinaryImage = {
  src: string;
  alt?: string;
  className?: string;
  title?: string;
  width?: number;
  height?: number;
};

const CloudinaryImage: React.FunctionComponent<CloudinaryImage> = ({
  src,
  alt,
  className,
  title,
  width,
  height,
}) => {
  const image = transformImage(src, width, height);

  return (
    <AdvancedImage cldImg={image} className={className} alt={alt} title={title} loading="lazy" />
  );
};

export default React.memo(CloudinaryImage);
