import * as React from 'react';
import { useState, useEffect } from 'react';
import styled from 'styled-components';
import FieldGroup from '@adecco/base-app/src/components/atoms/FieldGroup/FieldGroup';
import FormRow from '@adecco/base-app/src/components/atoms/FormRow/FormRow';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import FileUpload from '@adecco/base-app/src/components/molecules/FileUpload/FileUpload';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';
import ErrorMessage from '@adecco/base-app/src/components/atoms/ErrorMessage/ErrorMessage';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';
import { useFormContext } from 'react-hook-form';
import { MAX_SIZE } from '@adecco/base-app/src/constants/fileUpload';

export interface FileUploadFormElement {
  optionalHeadline: string;
  errors: {
    file1: boolean;
    file4: boolean;
  };
  isOptional: boolean;
}

const FileUploadWrappedHeadline = styled(Headline)`
  font-family: ${(props) => props.theme.fonts.HeadlineBold};
  font-size: ${calcrem(18)};
  line-height: ${calcrem(20)};
  margin-bottom: 10px;
  margin-top: 4px;
  transition: margin-bottom 0.4s ease-in-out;
  padding: ${(props) => props.theme.paddings.fileUploadHeadline};

  ${moreThan('md')} {
    padding: ${(props) => props.theme.paddings.fileUploadHeadlineWideScreen};
    margin-top: 0;
    margin-bottom: 18px;
  }
`;

const FileUploadFormElement: React.FunctionComponent<FileUploadFormElement> = ({
  optionalHeadline,
  errors,
  isOptional,
}) => {
  const { t } = useAppContext();
  const { watch, setValue } = useFormContext();
  const [cVfileErrorMessage, setCVfileErrorMessage] = useState<string | null>(null);
  const watchFiles = watch(['file1', 'file4']);

  useEffect(() => {
    const size1 = (watchFiles[0] && watchFiles[0][0]?.size) || 0;

    if (errors.file1) {
      if (size1 > MAX_SIZE) {
        setCVfileErrorMessage(t('error-upload-files-size'));
        return;
      }

      setCVfileErrorMessage(t('error-upload-resume'));
      return;
    }

    setCVfileErrorMessage(null);
  }, [watchFiles]);

  return (
    <>
      {isOptional && (
        <FileUploadWrappedHeadline skin={3}>{optionalHeadline}</FileUploadWrappedHeadline>
      )}
      <FormRow>
        <FieldGroup size={1}>
          <FileUpload
            label={`${t('form-field-upload-resume')}`}
            cvMaker={t("form-field-create-cv")}
            name="file1"
            placeholder={t('form-field-upload-placeholder')}
            helpText={t('form-field-cv-upload-help-info-text')}
            multiple={false}
            error={errors.file1}
            required={!isOptional}
            onClear={() => setValue('file1', [])}
          />
          {cVfileErrorMessage && (
            <ErrorMessage text={cVfileErrorMessage} paddingTop elementName="file1" />
          )}
        </FieldGroup>
      </FormRow>
      <FormRow>
        <FieldGroup size={1}>
          <FileUpload
            label={`${t('form-field-upload-documents')}`}
            name="file4"
            multiple
            placeholder={t('form-field-upload-placeholder')}
            helpText={t('form-field-documents-upload-help-info-text')}
            error={errors.file4}
            onClear={() => setValue('file4', [])}
          />
          {errors.file4 && (
            <ErrorMessage text={t('error-upload-files-size')} paddingTop elementName="file4" />
          )}
        </FieldGroup>
      </FormRow>
    </>
  );
};

export default FileUploadFormElement;
