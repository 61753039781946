import * as React from 'react';
import styled from 'styled-components';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import Paragraph from '@adecco/base-app/src/components/atoms/Paragraph/Paragraph';
import BrandedBrandBar from '@adecco/base-app/src/components/molecules/BrandedBrandBar/BrandedBrandBar';
import {
  BrandedLandingpageLocationsApplicationType,
  BrandedLocationElementType,
  FormType,
  BrandedLogoType,
} from '@adecco/base-app/src/gql/schema/generated';
import HighlightSpeechBubble from '@adecco/base-app/src/components/molecules/HighlightSpeechBubble/HighlightSpeechBubble';
import LocationList from '@adecco/base-app/src/components/molecules/LocationList/LocationList';
import { LandingPageHeadline } from '@adecco/base-app/src/components/organisms/LandingPageHeader/LandingPageHeader';
import FormRenderer from '@adecco/base-app/src/components/organisms/FormRenderer/FormRenderer';
import transformLink from '@adecco/base-app/src/utilities/transformLink';
import { routeNames } from '@adecco/base-app/src/constants/routeNames/routeNames';
import transformFormFieldsSet from '@adecco/base-app/src/utilities/forms/transformFormFieldsSet';
import { useRouter } from 'next/router';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import transformToOptions from '@adecco/base-app/src/utilities/forms/transformToOptions';
import { PageType } from '@adecco/base-app/pages/lpc';

export interface IBrandedLandingPageHeader {
  title?: string;
  subline?: string;
  sid?: string;
  description?: string;
  color?: string;
  bubbleText?: string;
  locationLinksTitle: string;
  brandedlandingpagelogoSet: ReadonlyArray<BrandedLogoType>;
  brandedlandingpagelocationsSet: readonly BrandedLocationElementType[];
  brandedlandingpagelocationsapplicationSet: readonly BrandedLandingpageLocationsApplicationType[];
  form?: FormType;
  brand?: string;
  kldb: string;
  buzzwords?: string;
  setFormIsDirty: (value: boolean) => void;
  monaAiId?: string;
  monaAiPostingId?: string;
}

interface IFormRendererStyled {
  shadow?: string;
}

const PageHeadWrapper = styled(Container)`
  background-color: white;
  margin: 70px auto 0;
  padding: 0;
  width: calc(100% - 40px);
`;

export const BarWrapper = styled.div`
  position: relative;
  border-top: ${(props) =>
    `${props.theme.heights.brandBarAfter} solid ${
      props.theme.brandColor ? props.theme.brandColor : '#000'
    }`};
  display: flex;
  flex-direction: column;

  ${moreThan('xl')} {
    flex-direction: row;
  }
`;

const StyledHighlightSpeechBubble = styled(HighlightSpeechBubble)`
  margin-inline: auto 20px;
  margin-block: 20px;

  ${moreThan('md')} {
    margin-right: 95px;
  }

  ${moreThan('xl')} {
    position: absolute;
    max-width: 220px;
    bottom: 0;
    //--> consider 4 logos with max width: (4 * 160px)
    left: calc(1120px - 240px);
    margin: auto;
  }

  p {
    font-size: 18px;
    line-height: 28px;
  }

  &:after {
    content: '';
    display: block;
    position: absolute;
    left: ${(props) => props.theme.positions.speechBubbleAfterLeft};
    bottom: ${(props) => props.theme.positions.speechBubbleAfterBottom};
    width: 0;
    height: 0;
    border-style: solid;
    border-width: ${(props) => props.theme.borders.speechBubbleAfter};
    border-color: ${(props) => props.theme.backgrounds.speechBubbleWrapper} transparent transparent
      transparent;
  }
`;

const BrandedLandingPageHeaderStyled = styled(Container)`
  position: relative;
  width: 100%;
  padding: 0 20px;

  ${moreThan('md')} {
    padding: 0 95px;
  }
`;

export const BrandedLandingPageHeadline = styled(LandingPageHeadline)`
  font-weight: 700;
  font-size: ${({ theme }) => theme.fontSizes.brandedHeadline};
  ${moreThan('md')} {
    font-size: ${({ theme }) => theme.fontSizes.brandedHeadlineWideScreen};
  }
`;

const BrandedLandingPageHeaderText = styled.div`
  width: 100%;
  margin-bottom: 60px;
  margin-top: 24px;

  ${moreThan('md')} {
    margin-top: 60px;
  }
`;

const ParagraphStyled = styled(Paragraph)`
  margin-top: 20px;
  font-size: ${(props) => props.theme.fontSizes.landingPageParagraph};
  line-height: ${(props) => props.theme.lineHeights.landingPageParagraph};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.landingPageParagraphWideScreen};
    line-height: ${(props) => props.theme.lineHeights.landingPageParagraphWideScreen};
  }
`;

const FormRendererStyled = styled.div<IFormRendererStyled>`
  ${(props) =>
    props.shadow &&
    `
    form {
      position: relative;
      margin: 0;

      &:before {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        box-shadow: 5px 5px 25px ${props.shadow};
        border-radius: ${props.theme.radius.panel};
        opacity: .20;
        pointer-events: none;
      };
    };
  `}
`;

export const BrandedLandingPageSubline = styled(Headline)`
  font-size: ${(props) => props.theme.fontSizes.brandedSubline};
  line-height: ${(props) => props.theme.lineHeights.brandedSubline};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.brandedSublineWideScreen};
    line-height: ${(props) => props.theme.lineHeights.brandedSublineWideScreen};
    font-weight: 600;
  }
`;

const BrandedLandingPageHeader: React.FunctionComponent<IBrandedLandingPageHeader> = ({
  title,
  subline,
  description,
  color,
  bubbleText,
  locationLinksTitle,
  brandedlandingpagelogoSet,
  brandedlandingpagelocationsSet,
  brandedlandingpagelocationsapplicationSet,
  form,
  brand,
  kldb,
  sid,
  buzzwords,
  setFormIsDirty,
  monaAiId,
  monaAiPostingId,
}) => {
  const router = useRouter();
  const currentCountry = router.locale?.split('-')[0];
  const customFormOptions = transformToOptions(form?.formoptionsSet ?? []);

  return (
    <PageHeadWrapper>
      <BarWrapper>
        <BrandedBrandBar
          brandedLogos={[...brandedlandingpagelogoSet]}
          withSpeechBubble={!!bubbleText}
        />
        {bubbleText && <StyledHighlightSpeechBubble text={bubbleText} />}
      </BarWrapper>
      <BrandedLandingPageHeaderStyled>
        <BrandedLandingPageHeaderText>
          <BrandedLandingPageHeadline>{title}</BrandedLandingPageHeadline>
          <BrandedLandingPageSubline skin={2} tag="h3">
            {subline}
          </BrandedLandingPageSubline>
          {description ? <ParagraphStyled>{description}</ParagraphStyled> : null}
          {brandedlandingpagelocationsSet.length > 0 && (
            <LocationList headline={locationLinksTitle} items={brandedlandingpagelocationsSet} />
          )}
        </BrandedLandingPageHeaderText>
        <FormRendererStyled shadow={color}>
          <FormRenderer
            formStructure={transformFormFieldsSet(form ? [...form.formfieldsSet] : [])}
            submitRoute={transformLink(
              router.asPath,
              /* @ts-ignore */
              routeNames[currentCountry].applicationSentRoute,
              { queryString: false }
            )}
            varType={`CDX-LP-Client-${brand}-${kldb}`}
            doi
            /* @ts-ignore */
            locations={brandedlandingpagelocationsapplicationSet}
            desiredPosition={title}
            bigSubmit
            withFileUpload={
              customFormOptions.withFileUpload || customFormOptions.cvMandatory
                ? { optional: !customFormOptions.cvMandatory }
                : undefined
            }
            setFormIsDirty={setFormIsDirty}
            pageType={PageType}
            formName={form?.name}
            buzzwords={buzzwords}
            monaAiId={monaAiId}
            monaAiPostingId={monaAiPostingId}
            sid={sid}
          />
        </FormRendererStyled>
      </BrandedLandingPageHeaderStyled>
    </PageHeadWrapper>
  );
};

export default BrandedLandingPageHeader;
