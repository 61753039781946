import * as React from 'react';
import styled from 'styled-components';
import Headline from '@adecco/base-app/src/components/atoms/Headline/Headline';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';
import Paragraph from '@adecco/base-app/src/components/atoms/Paragraph/Paragraph';
import Container from '@adecco/base-app/src/components/atoms/Container/Container';
import { CloudinaryImageType, FormType } from '@adecco/base-app/src/gql/schema/generated';
import DynamicImage from '@adecco/base-app/src/components/atoms/DynamicImage/DynamicImage';

import { routeNames } from '@adecco/base-app/src/constants/routeNames/routeNames';
import FormRenderer from '@adecco/base-app/src/components/organisms/FormRenderer/FormRenderer';
import transformLink from '@adecco/base-app/src/utilities/transformLink';
import { useRouter } from 'next/router';
import transformFormFieldsSet from '@adecco/base-app/src/utilities/forms/transformFormFieldsSet';
import transformToOptions from '@adecco/base-app/src/utilities/forms/transformToOptions';
import {
  TopJobsQualified as TopJobsQualifiedStructure,
  TopJobs as TopJobsStructure,
} from '@adecco/base-app/src/constants/FormStructures/FormStructure';
import { PageType } from '@adecco/base-app/pages/lpkldb';

export interface ILandingPageHeader {
  groupId: string;
  title?: string;
  subline?: string;
  description?: string;
  image?: string;
  cloudinaryImage?: CloudinaryImageType;
  formType: number;
  sid?: string;
  form?: FormType;
  setFormIsDirty?: (value: boolean) => void;
  locations?: { name: string; sid?: string }[];
  buzzwords?: string;
  monaAiId?: string;
  monaAiPostingId?: string;
}

export const LandingPageHeaderStyled = styled.div`
  width: 100%;
  display: flex;
  flex-wrap: wrap;
`;

export const LandingPageHeadline = styled(Headline)`
  margin-bottom: ${(props) => props.theme.margins.landingPageHeadline};
  font-size: ${(props) => props.theme.fontSizes.headline1};
  line-height: ${(props) => props.theme.lineHeights.landingPageHeadline};

  ${moreThan('md')} {
    font-size: ${(props) => props.theme.fontSizes.headline1WideScreen};
    line-height: ${(props) => props.theme.lineHeights.landingPageHeadlineWideScreen};
  }
`;

const LandingPageHeaderText = styled.div`
  ${moreThan('md')} {
    width: 50%;
  }
`;

export const LandingPageHeaderImage = styled.div`
  display: none;
  padding-top: 20px;

  ${moreThan('md')} {
    display: block;
    width: 50%;
    padding-left: 50px;
  }
`;

export const LandingPageImg = styled(DynamicImage)<{
  onlyXs?: boolean;
}>`
  display: inline-flex;
  aspect-ratio: 1;
  object-fit: contain;
  width: 100%;
  margin-bottom: ${({ theme }) => theme.margins.landingPageImage};

  ${moreThan('md')} {
    display: ${(props) => (props.onlyXs ? 'none' : 'block')};
  }
`;

const LandingPageParagraph = styled(Paragraph)`
  font-size: ${(props) => props.theme.fontSizes.landingPageParagraph};
  margin-bottom: ${(props) => props.theme.margins.landingPageParagraph};
  line-height: ${(props) => props.theme.lineHeights.landingPageParagraph};

  ${moreThan('md')} {
    margin-bottom: ${(props) => props.theme.margins.landingPageParagraphWideScreen};
    font-size: ${(props) => props.theme.fontSizes.landingPageParagraphWideScreen};
    line-height: ${(props) => props.theme.lineHeights.landingPageParagraphWideScreen};
  }
`;

const LeadFormStyled = styled.div`
  margin-top: ${(props) => props.theme.margins.form};
  padding-top: 0;
  padding-bottom: 0;
`;

const LandingPageHeader: React.FunctionComponent<ILandingPageHeader> = ({
  groupId,
  title,
  subline,
  description,
  image,
  cloudinaryImage,
  formType,
  sid,
  form,
  setFormIsDirty,
  locations,
  buzzwords,
  monaAiId,
  monaAiPostingId,
}) => {
  const router = useRouter();
  const currentCountry = router.locale?.split('-')[0];
  const customFormOptions = transformToOptions(form?.formoptionsSet ?? []);
  const LandingPageFormStructure = formType !== 1 ? TopJobsStructure : TopJobsQualifiedStructure;
  const formStructure = form
    ? transformFormFieldsSet([...form.formfieldsSet] ?? [])
    : LandingPageFormStructure;
  const isSalesforce = form ? customFormOptions.isSalesforce : formType !== 1;
  const mappedLocations = locations?.map((location) => {
    return {
      name: location.name,
      sid: location.sid!,
    };
  });

  return (
    <Container>
      <LandingPageHeaderStyled>
        <LandingPageHeaderText>
          <LandingPageHeadline>{title}</LandingPageHeadline>
          <Headline skin={2} tag="h3">
            {subline}
          </Headline>
          <LandingPageImg image={image} alt={title} cloudinaryImage={cloudinaryImage} onlyXs />
          {description ? <LandingPageParagraph>{description}</LandingPageParagraph> : null}
          <LeadFormStyled>
            <FormRenderer
              formStructure={formStructure}
              submitRoute={
                formType !== 1
                  ? transformLink(
                      router.asPath,
                      // @ts-ignore
                      routeNames[currentCountry].applyRoute,
                      { queryString: false }
                    )
                  : transformLink(
                      router.asPath,
                      // @ts-ignore
                      routeNames[currentCountry].applicationSentRoute,
                      { queryString: false }
                    )
              }
              varType={`CDX-LP-TopJobs-Qualified-${groupId}`}
              sid={sid}
              doi={customFormOptions.withDoi}
              isSalesforce={isSalesforce}
              formHeader={customFormOptions.formHeader}
              bigSubmit={customFormOptions.bigSubmit}
              desiredPosition={title!}
              kldb={groupId}
              withFileUpload={
                customFormOptions.withFileUpload || customFormOptions.cvMandatory
                  ? { optional: !customFormOptions.cvMandatory }
                  : undefined
              }
              setFormIsDirty={setFormIsDirty}
              pageType={PageType}
              formName={form?.name}
              locations={mappedLocations}
              buzzwords={buzzwords}
              monaAiId={monaAiId}
              monaAiPostingId={monaAiPostingId}
            />
          </LeadFormStyled>
        </LandingPageHeaderText>
        <LandingPageHeaderImage>
          <LandingPageImg image={image} alt={title} cloudinaryImage={cloudinaryImage} />
        </LandingPageHeaderImage>
      </LandingPageHeaderStyled>
    </Container>
  );
};

export default LandingPageHeader;
