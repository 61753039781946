import Script from 'next/script';
import { useEffect, useState } from 'react';

export const ONETRUST_READY_EVENT_NAME = 'onetrustready';

export const WithTracking = ({
  tealiumSrc,
  children,
}: {
  tealiumSrc?: string;
  children: React.ReactNode;
}) => {
  const [consent, setConsent] = useState(false);

  const requiredConsent = ['C0002', 'C0004'];

  function handleOptanonReady() {
    if (!window.OnetrustActiveGroups) {
      return;
    }

    const activeGroups = window.OnetrustActiveGroups.split(',');

    function hasConsent(categories: string[]) {
      return categories.some((category) => requiredConsent.some((group) => group === category));
    }

    const userConsent = hasConsent(activeGroups);

    setConsent(userConsent);

    window.OneTrust.OnConsentChanged(({ detail }: { detail: string[] }) => {
      const userConsent = hasConsent(detail);

      setConsent(userConsent);
    });
  }

  useEffect(() => {
    document.addEventListener(ONETRUST_READY_EVENT_NAME, handleOptanonReady);

    return () => document.removeEventListener(ONETRUST_READY_EVENT_NAME, handleOptanonReady);
  }, []);

  return (
    <>
      {tealiumSrc && consent && (
        <>
          <Script
            type="text/javascript"
            id="tealium-utag-data"
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html:
                'var utag_data = { };window.utag_cfg_ovrd = window.utag_cfg_ovrd || {};window.utag_cfg_ovrd.noview = true',
            }}
          />
          <Script
            type="text/javascript"
            id="tealium"
            dangerouslySetInnerHTML={{
              __html: `(function(a,b,c,d) {
                a='${tealiumSrc}';
                b=document;c='script';d=b.createElement(c);d.src=a;
                d.type='text/java'+c;d.async=true;
                a=b.getElementsByTagName(c)[0];a.parentNode.insertBefore(d,a)})();`,
            }}
          />
        </>
      )}
      {children}
    </>
  );
};
