import * as React from 'react';
import styled from 'styled-components';
import { moreThan } from '@adecco/base-app/src/constants/breakpoints/breakpoints';

interface ICvMaker {
  text: string;
  className?: string;
}

export const CvMakerStyled = styled.span`
  display: inline-block;
  font-size: ${(props) => props.theme.fontSizes.dateParagraph};
  a {
    text-decoration: underline;
    color: ${(props) => props.theme.colors.cvLink};
  }
  ${moreThan('sm')} {
    font-size: ${(props) => props.theme.fontSizes.checkText};
  }
`;

const CvMaker: React.FunctionComponent<ICvMaker> = ({ text, className }) => {
  return text !== 'form-field-create-cv' ? (
    <CvMakerStyled className={className} dangerouslySetInnerHTML={{ __html: text }} />
  ) : null;
};

export default CvMaker;
