import calcrem from '@adecco/base-app/src/utilities/calcrem/calcrem';

export const xsPx = 300;
export const smPx = 500;
export const mdPx = 768;
export const lgPx = 992;
export const maxWidthPx = 1120;
export const xlPx = 1200;
export const xlmPx = 1400;
export const xxlPx = 1600;

export const xs = calcrem(xsPx);
export const sm = calcrem(smPx);
export const md = calcrem(mdPx);
export const lg = calcrem(lgPx);
export const maxWidth = calcrem(maxWidthPx);
export const xl = calcrem(xlPx);
export const xlm = calcrem(xlmPx);
export const xxl = calcrem(xxlPx);

export const breakpoints = {
  xs,
  sm,
  md,
  lg,
  maxWidth,
  xl,
  xlm,
  xxl,
};

const breakpointNames = ['xs', 'sm', 'md', 'lg', 'maxWidth', 'xl', 'xlm', 'xxl'];

export type breakpointNamesTypes = 'xs' | 'sm' | 'md' | 'lg' | 'maxWidth' | 'xl' | 'xlm' | 'xxl';

const maxBreakpoints: {
  xs: string;
  sm: string;
  md: string;
  lg: string;
  maxWidth: string;
  xl: string;
  xlm: string;
  xxl: string;
} = {
  xs: calcrem(xsPx - 1),
  sm: calcrem(smPx - 1),
  md: calcrem(mdPx - 1),
  lg: calcrem(lgPx - 1),
  maxWidth: calcrem(maxWidthPx - 1),
  xl: calcrem(xlPx - 1),
  xlm: calcrem(xlmPx - 1),
  xxl: calcrem(xxlPx - 1),
};

const isMobile = () => (typeof window !== 'undefined' ? window.innerWidth < mdPx : false);

const moreThan = (breakpoint: breakpointNamesTypes): string =>
  `@media(min-width: ${maxBreakpoints[breakpoint]})`;

const lessThan = (breakpoint: breakpointNamesTypes): string =>
  `@media(max-width: ${maxBreakpoints[breakpoint]})`;

const between = (breakpoint1: breakpointNamesTypes, breakpoint2: breakpointNamesTypes): string => {
  return `@media(min-width: ${maxBreakpoints[breakpoint1]}) and (max-width: ${maxBreakpoints[breakpoint2]})`;
};

export default breakpoints;
export { breakpointNames, maxBreakpoints, isMobile, moreThan, lessThan, between };
