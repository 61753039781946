import { useState } from 'react';
import { GetServerSideProps, NextPage } from 'next';
import Head from 'next/head';
import JobApplication from '@adecco/base-app/src/components/templates/JobApplication/JobApplication';
import { getContractType, useDataLayer } from '@adecco/base-app/src/hooks/useDatalayer';
import {
  BenefitsElementType,
  JobAdType,
  JobApplyDocument,
  JobApplyQuery,
} from '@adecco/base-app/src/gql/schema/generated';
import { withApolloProps } from '@adecco/base-app/src/apollo/withApolloProps';
import getConfig from 'next/config';
import getConfiguration from '@adecco/base-app/src/utilities/getConfiguration/getConfiguration';
import getBenefitStringForMetaDescription from '@adecco/base-app/src/utilities/transformText/getBenefitStringForMetaDescription';
import { EventData, useDataLayerPushEvent } from '@adecco/base-app/src/hooks/useDataLayerPushEvent';
import { isRedirect } from '@adecco/base-app/src/hooks/isRedirect';
import { ITEM_BRANDS } from '@adecco/base-app/src/constants/itemBrands';
import { useAppContext } from '@adecco/base-app/src/utilities/appProvider/appProvider';

interface IIndex {
  data: {
    jobad: JobAdType;
  };
}

export const PageType = 'Job application process';

const AdeccoFormPage: NextPage<IIndex> = ({ data }) => {
  const [formIsDirty, setFormIsDirty] = useState(false);
  const { brand } = useAppContext();

  useDataLayer(data.jobad, PageType, false);
  useDataLayerPushEvent({ event: 'page_view' });
  useDataLayerPushEvent({ event: 'candidature_start' });

  const eventData: EventData = {
    ecommerce: {
      affiliation: 'standardjob',
      items: [
        {
          item_name: data.jobad.title || 'na',
          item_id: data.jobad.sourceJobId || 'na',
          item_category: data.jobad.occupationGroup || 'na',
          item_category2: data.jobad.occupationMainGroup || 'na',
          item_category3: data.jobad.jobLocation || 'na',
          item_category4: data.jobad.sourceAdId || 'na',
          item_category5: data.jobad.kldbNumber || 'na',
          item_variant: getContractType(data.jobad.employmentType),
          item_brand: ITEM_BRANDS[brand],
          client: 'na',
        },
      ],
    },
  };

  useDataLayerPushEvent({
    event: 'view_cart',
    data: eventData,
  });

  useDataLayerPushEvent({
    event: 'begin_checkout',
    data: eventData,
    deps: [formIsDirty],
    checker: formIsDirty,
  });

  isRedirect(data, 'applyRoute');

  const title = data.jobad.title ? `${data.jobad.title} | Bewerbung` : 'Bewerbung';
  let description = `Job als ${data.jobad.occupation}`;

  if (data.jobad.jobLocation) {
    description += ` in ${data.jobad.jobLocation}`;
  }

  const benefitsElement = (data.jobad as JobAdType).group?.elements?.edges?.find(
    (edge) => (edge?.node as BenefitsElementType)?.benefitselementitemSet !== undefined
  );

  if (benefitsElement) {
    const items = (benefitsElement.node as BenefitsElementType).benefitselementitemSet;
    const titles = items.slice(0, 3).map((benefit) => benefit.title);

    if (items.length > 0) {
      description += getBenefitStringForMetaDescription(titles);
    }
  }

  return (
    <>
      <Head>
        <title>{title}</title>
        <meta property="og:type" content="website" />
        <meta property="og:title" content={title} />
        <meta name="description" content={description} />
        <meta property="og:description" content={description} />
        {data.jobad && data.jobad.group?.ogImage ? (
          <meta property="og:image" content={data.jobad.group?.ogImage} />
        ) : null}
        <meta name="robots" content="noindex, follow" />
        <meta name="application-name" content="ats:adeccogroup" />
      </Head>
      <JobApplication
        jobData={data.jobad}
        id={data.jobad.sourceJobId}
        setFormIsDirty={setFormIsDirty}
      />
    </>
  );
};

export const getServerSideProps: GetServerSideProps = (context) =>
  withApolloProps(async (client) => {
    if (!context.params)
      return {
        notFound: true,
      };
    const { publicRuntimeConfig } = getConfig();
    const { id } = context.params;

    const jobId = String(id);
    const { data } = await client.query<JobApplyQuery>({
      query: JobApplyDocument,
      variables: {
        sourceAdId: jobId,
        languages: publicRuntimeConfig.LANGUAGES,
        countryCode: context.locale ?? '',
      },
      errorPolicy: 'ignore',
    });

    if (
      data.jobad === null ||
      getConfiguration(data.frontendConfigurations, 'withJobpage') === 'false'
    ) {
      return {
        notFound: true,
      };
    }

    return {
      props: {
        data,
        pageType: PageType,
      },
    };
  });

export default AdeccoFormPage;
