type IFormOptionSet = readonly {
  readonly value: boolean;
  readonly option: { readonly name: string };
}[];

const transformToOptions = (formOptions: IFormOptionSet) => {
  const defaultFormOptions = {
    isSalesforce: false,
    withDoi: true,
    formHeader: false,
    bigSubmit: false,
    withFileUpload: false,
    cvMandatory: false,
  };

  formOptions.forEach((formOption) => {
    // @ts-ignore
    defaultFormOptions[formOption.option.name] = formOption.value;
  });

  return defaultFormOptions;
};

export default transformToOptions;
